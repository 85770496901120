import { useState } from 'react';
import { NButton, NCheck, NInput, useToast } from 'nayan';
import { Link, useNavigate } from 'react-router-dom';
import { signupUser } from '../../shared/services/AuthService';
import AcceptTerms from '../helpers/AcceptTerms';
import { Required } from '../helpers/Required';

const Signup = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if (!name || !email || !password) {
      toast('You have to enter required details to continue.');
      return;
    }
    if (!isActive) {
      toast('You have to accept Our Terms to continue.');
      return;
    }
    setIsLoading(true);
    const userData = Object.assign({ name, email, password });
    signupUser(userData)
      .then((res: any) => {
        toast(res.data.message);
        navigate('/login', { replace: true });
      })
      .catch((error: any) => {
        toast(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <NInput className="mb-3 card-input" label="Full Name" type="text" onChange={e => setName(e.target.value)} required={true} />
        <NInput className="mb-3 card-input" label="Email" type="email" onChange={e => setEmail(e.target.value)} required={true} />
        <NInput className="mb-3 card-input" label="Password" type="password" onChange={e => setPassword(e.target.value)} required={true} />

        <div className="form-check mb-2">
          <NCheck id="signup-check" checked={isActive} onChange={(checked: any) => setIsActive(checked)}>
            <AcceptTerms />
            <Required />
          </NCheck>
        </div>

        <NButton type="submit" className="w-full mb-3" isLoading={isLoading}>
          SUBMIT
        </NButton>
      </form>

      <div className="text-center text">
        Already have an account ?{' '}
        <Link className="text-primary cursor-pointer" to="/login">
          Login
        </Link>
      </div>
    </>
  );
};

export default Signup;
