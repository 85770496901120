import { NBadge } from 'nayan';
import { capitalizeFirstChar } from '../../shared/services/Utils';

interface Props {
  tags: string[];
}

const Tags = (props: Props) => {
  return (
    <div className="video-tags mb-3">
      <div className="text-base mb-8 leading-relaxed">
        Discover a multitude of categories to create unique and personalized video invitations and heartfelt wishes for every occasion. Whether you're
        planning a birthday celebration, a wedding announcement, a baby shower, or a holiday greeting, our platform offers a variety of themed
        templates to suit your needs. From elegant and formal designs to playful and whimsical styles, explore different categories to find the
        perfect template that reflects your event's personality and captures your heartfelt sentiments. Start crafting your memorable video invitation
        or wish today with our diverse range of categories.
      </div>
      <div className="flex flex-row flex-wrap items-center">
        {props.tags.map((tag, index) => (
          <NBadge key={index} size="MD" className="text-text bg-card border border-border mr-3 py-1 mb-3">
            {capitalizeFirstChar(tag)}
          </NBadge>
        ))}
      </div>
    </div>
  );
};

export default Tags;
