import { useMemo } from 'react';
import { z } from 'zod';
import { getTemplateVideo, templateSkus } from '../../../src/services/TemplatesService';
import WishesContent from '../../helpers/WishesContent';
import { Wraper } from '../../helpers/Wraper';
import festivalMusic from './assets/diwali.mp3';
import festivalVideo from './assets/festival-diwali.mp4';
import { festivalSchema } from './FestivalsSchema';

const FestivalWishesForDiwali: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#f8c933', name, message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalDiwaliWishes), []);
  const labels = template.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} music={festivalMusic}>
      <WishesContent
        className="w-[850px] flex flex-col justify-end mx-auto pb-16"
        labels={labels}
        language={language}
        name={name}
        message={message}
        color={color}
      />
    </Wraper>
  );
};

export default FestivalWishesForDiwali;
