import { useState } from 'react';
import { NButton, NInput, useToast } from 'nayan';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../shared/services/AuthService';

const Forgot = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if (!!email) {
      setIsLoading(true);
      forgotPassword({ email })
        .then((res: any) => {
          toast(res.data.message);
          navigate('/login', { replace: true });
        })
        .catch((err: any) => toast(err.message))
        .finally(() => setIsLoading(false));
    } else {
      toast('Enter valid email address');
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <NInput className="mb-3 card-input" label="Email address" type="email" onChange={e => setEmail(e.target.value)} required={true} />
        <NButton type="submit" className="w-full mb-3" isLoading={isLoading}>
          RESET PASSWORD
        </NButton>
      </form>

      <div className="text-center text">
        Don't have an account ?{' '}
        <Link className="text-primary cursor-pointer" to="/signup">
          Signup
        </Link>
      </div>
    </>
  );
};

export default Forgot;
