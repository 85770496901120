import { differenceInDays } from 'date-fns';
import { NCard, NLoading, NTable } from 'nayan';
import { formatQueryData } from '../../shared/hooks/hooksUtils';
import { DateService } from '../../shared/services/DateService';
import { usePaymentsData } from '../../shared/services/PaymentsService';
import SubHeader from '../helpers/SubHeader';
import Payment from '../payments/Payment';

const AccountSubscriptions = () => {
  const { isLoading, data } = usePaymentsData();
  const payments = formatQueryData(data);

  const columnDef = [
    { name: 'uuid', title: 'Payment ID' },
    {
      name: 'title',
      title: 'Description',
      component: ({ row }) => (!!row.title ? `Payment for ${row.title} video.` : `Payment for Inyter yearly subscription.`)
    },
    { name: 'amount', title: 'Amount', component: ({ row }) => `₹${row.amount / 100}` },
    { name: 'status', title: 'Status', component: ({ row }) => (row.status === 'CREATED' ? 'FAILED' : row.status) },
    { name: 'created', title: 'Created', component: ({ row }) => DateService.format(row.created) }
  ];

  if (isLoading) {
    return <NLoading />;
  }

  const hasActiveSubscription = () => {
    return payments.some(item => {
      return !item.videoUuid && item.status === 'SUCCESS' && differenceInDays(new Date(), new Date(item.created)) <= 365;
    });
  };

  const getActiveSubscription = () => {
    const activeSubscription = payments.find(item => {
      return !item.videoUuid && item.status === 'SUCCESS' && differenceInDays(new Date(), new Date(item.created)) <= 365;
    });
    return activeSubscription && 365 - differenceInDays(new Date(), new Date(activeSubscription.created));
  };

  return (
    <div className="subscriptions">
      <SubHeader title="Subscriptions" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
        <NCard tabIndex={0} className={`col-span-1 p-3 border-2 ${!hasActiveSubscription() ? 'border-primary' : 'border-border'}`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Basic</div>
            <div className="text-[18px]">
              ₹0<span className="text-sm"> / year.</span>
            </div>
          </div>
          <div className="text-sm mb-3 h-[100px]">
            Lifetime access includes invitations and wishes with watermarks and lower resolution. <br />
            <br />
            <span className="text-primary">No additional subscription needed for this.</span>
          </div>
        </NCard>
        <NCard tabIndex={0} className={`col-span-1 p-3 border-2 ${!!hasActiveSubscription() ? 'border-primary' : 'border-border'}`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Premium</div>
            <div className="text-[18px]">
              ₹9999<span className="text-sm"> / year.</span>
            </div>
          </div>
          <div className="text-sm mb-3 h-[100px]">
            Pay for year and get watermark-free, high-resolution invitations and wishes that are schedulable.
            <br />
            <br />
            <span className="text-primary">
              {hasActiveSubscription()
                ? 'Already subscribed, Subscription will end in ' + getActiveSubscription() + ' days.'
                : 'Subscribe premium to get additional features.'}
            </span>
          </div>
          <div className="text-right">
            <Payment amount="999900" title="UPGRADE" disabled={!!hasActiveSubscription()} />
          </div>
        </NCard>
      </div>

      <div className="text-lg mb-3">Payments History</div>
      {!!payments.length && <NTable className="bg-card w-full" columnDef={columnDef} data={payments} />}
      {!payments.length && <NCard className="p-5 text-center">No payments history available.</NCard>}
    </div>
  );
};

export default AccountSubscriptions;
