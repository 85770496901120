import { useEffect, useState } from 'react';
import { BadgeCheck } from 'lucide-react';
import { NButton, NLoading } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { verifyAccount } from '../../shared/services/AuthService';
import { Meta } from '../helpers/Meta';

const Verify = () => {
  const navigate = useNavigate();
  const { userUuid, resetUuid }: any = useParams();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    verifyAccount({ userUuid, resetUuid })
      .then((res: any) => setMessage(res.data.message))
      .catch((err: any) => setMessage(err.message))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="verify">
      <Meta title="Verify Account" />
      <div className="flex justify-center">
        <div className="w-full md:max-w-[450px]">
          {isLoading && <NLoading />}
          {!isLoading && (
            <div className="text-center">
              <BadgeCheck className="w-20 h-20 mx-auto md:mt-16 mt-8 text-primary" />
              <div className="text mb-5 mt-5">{message}</div>
              <NButton onClick={() => navigate('/login', { replace: true })}>Click here to Login</NButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verify;
