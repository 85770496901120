import { cn } from 'nayan';

interface Props {
  className?: string;
  children: any;
}

export const Center = (props: Props) => {
  return <div className={cn(`w-full flex flex-col justify-center items-center ${props.className}`)}>{props.children}</div>;
};
