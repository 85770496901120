import { NCard, NInfiniteScroll, NLoading } from 'nayan';
import { Link } from 'react-router-dom';
import { formatInfiniteQueryData } from '../../shared/hooks/hooksUtils';
import { useNotificationsData } from '../../shared/services/ActionsService';
import { DateService } from '../../shared/services/DateService';
import { ACTION_TYPES } from '../../shared/types/types';
import SubHeader from '../helpers/SubHeader';

const AccountNotifications = () => {
  const { isLoading, data, fetchNextPage, hasNextPage, isFetching } = useNotificationsData();
  const notifications = formatInfiniteQueryData(data) || [];

  const notificationMapping = {
    [ACTION_TYPES.VIDEO_LIKE]: 'liked your video',
    [ACTION_TYPES.VIDEO_COMMENT]: 'commented on your video'
  };

  return (
    <div className="notifications">
      <SubHeader title="Notifications" />
      {!!isLoading && <NLoading />}
      {!isLoading && !notifications.length && <div className="p-10 text-center">No notifications available.</div>}
      {!isLoading && !!notifications.length && (
        <NInfiniteScroll
          next={() => !isFetching && fetchNextPage()}
          hasMore={!!hasNextPage}
          loader={<NLoading />}
          dataLength={notifications.length}
          scrollThreshold={0.99}>
          <div className="grid grid-cols-1 gap-3 mb-3 p-0.5">
            {notifications.map((video: any) => (
              <Link to={'/videos/' + video.videoUuid}>
                <NCard tabIndex={0} role="button" key={video.uuid} className="p-2">
                  <strong>{video.name}</strong>&nbsp;{notificationMapping[video.type]} &middot;{' '}
                  <span className="text-xs text-muted">{DateService.format(video.created)}</span>
                </NCard>
              </Link>
            ))}
          </div>
        </NInfiniteScroll>
      )}
    </div>
  );
};

export default AccountNotifications;
