import { cn } from 'nayan';
import { useCurrentFrame } from 'remotion';
import { fadeInEffect } from '../services/utils';

interface Props {
  from: number;
  to: number;
  children: any;
  color?: string;
  fontFamily?: string;
  className?: string;
}

export const Text = (props: Props) => {
  const { from, children, to, className = '', fontFamily = 'inherit', color = '#000000' } = props;
  const frame = useCurrentFrame();
  const opacity = fadeInEffect(frame, from, to);

  return (
    <div
      style={{ color, opacity, fontFamily }}
      className={cn(`text-black drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.8)] leading-relaxed ${className}`)}>
      {children}
    </div>
  );
};
