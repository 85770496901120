import { z } from 'zod';
import { LANGUAGES } from '../../../src/shared/config/constants';
import { FORM_TYPES } from '../../../src/shared/types/types';

export const marriageSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  surname: z.string(),
  message: z.string(),
  groomName: z.string(),
  groomImage: z.string(),
  groomFatherName: z.string(),
  groomMotherName: z.string(),
  brideName: z.string(),
  brideImage: z.string(),
  brideFatherName: z.string(),
  brideMotherName: z.string(),
  date: z.string(),
  venue: z.string()
});

export const marriageInvitationPlatforms = {
  web: {
    compositionWidth: 1280,
    compositionHeight: 720,
    compositionDuration: 450
  }
};

export const marriageInvitationForm = [
  { type: FORM_TYPES.TEXT, key: 'surname', label: 'Enter Family Name' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter Message' },
  { type: FORM_TYPES.TEXT, key: 'groomName', label: 'Enter Groom Name' },
  { type: FORM_TYPES.FILE, key: 'groomImage', label: 'Choose Groom Image' },
  { type: FORM_TYPES.TEXT, key: 'groomFatherName', label: 'Enter Groom Father Name' },
  { type: FORM_TYPES.TEXT, key: 'groomMotherName', label: 'Enter Groom Mother Name' },
  { type: FORM_TYPES.TEXT, key: 'brideName', label: 'Enter Bride Name' },
  { type: FORM_TYPES.FILE, key: 'brideImage', label: 'Choose Bride Image' },
  { type: FORM_TYPES.TEXT, key: 'brideFatherName', label: 'Enter Bride Father Name' },
  { type: FORM_TYPES.TEXT, key: 'brideMotherName', label: 'Enter Bride Mother Name' },
  { type: FORM_TYPES.TEXTAREA, key: 'date', label: 'Enter marriage timings' },
  { type: FORM_TYPES.TEXTAREA, key: 'venue', label: 'Enter venue details' }
];

export const marriageTags = [
  'online marriage invitations',
  'digital wedding invites',
  'custom marriage invitations',
  'wedding invitation templates',
  'online wedding wishes',
  'wedding card creation',
  'virtual marriage invitations',
  'personalized wedding invites',
  'wedding invitation design',
  'e-invitation for wedding',
  'wedding wishes online',
  'create wedding invitations',
  'wedding invitation maker',
  'DIY wedding invitations',
  'wedding invitation messages',
  'e-card wedding wishes',
  'wedding greeting cards online',
  'online wedding party invitations',
  'digital wedding greetings',
  'printable wedding invitations',
  'customizable wedding invites',
  'interactive wedding invitations',
  'wedding invitation cards',
  'unique wedding invitations',
  'wedding invitation ideas',
  'wedding party e-invites',
  'personalized wedding cards',
  'virtual wedding greetings',
  'wedding wishes design',
  'custom wedding wishes',
  'online wedding party cards',
  'wedding invitation customization',
  'digital wedding celebration invites',
  'wedding invitation online',
  'online wedding invitation services',
  'wedding invitation RSVP',
  'custom digital wedding invites',
  'wedding e-invite design',
  'personalized e-invitations',
  'wedding invitation graphics',
  'digital wedding cards',
  'interactive wedding e-cards',
  'wedding party invitation creation',
  'design wedding invitations online',
  'wedding celebration e-invites',
  'custom wedding e-cards',
  'digital wedding party invites',
  'wedding invitation design tools',
  'online wedding wishes creation',
  'unique digital wedding invitations',
  'personalized wedding invitation templates',
  'virtual wedding celebration cards',
  'editable wedding invitations',
  'online wedding event invitations',
  'digital wedding invite maker',
  'wedding invitations with RSVP',
  'online wedding wishes messages',
  'customizable e-wedding cards',
  'wedding invitation creation platform',
  'virtual wedding card designs',
  'wedding e-card creation tools',
  'create online wedding wishes',
  'digital wedding party cards',
  'custom wedding greeting e-cards',
  'wedding invitation designs online',
  'personalized digital wedding wishes'
];

export const marriageInvitationLanguages = {
  english: {
    labels: {
      welcomeMsg: 'OM GANESHAYA NAMAH',
      family: "FAMILY'S",
      weddingInvitation: 'WEDDING INVITATION',
      sonOf: 'Son of',
      daughterOf: 'Daughter of',
      mr: 'Mr.',
      mrs: 'Mrs.',
      weddingOn: 'WEDDING ON',
      venue: 'VENUE'
    },
    props: {
      type: 'web',
      language: 'english',
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      surname: 'Kohli',
      message: 'YOU ARE CORDIALLY INVITED TO CELEBRATE THE WEDDING OF',
      groomName: 'Virat Kohli',
      groomImage: '',
      groomFatherName: 'Late Prem Nath Kohli',
      groomMotherName: 'Saroj',
      brideName: 'Anushka Sharma',
      brideImage: '',
      brideFatherName: 'Rtd. Col. Ajay Kumar Sharma',
      brideMotherName: 'Ashima',
      date: 'Thursday, December 21st, 2017',
      venue: 'Dharbar Hall, Taj Diplomatic Enclave (SP Marg Entrance), New Delhi.'
    }
  },
  telugu: {
    labels: {
      welcomeMsg: 'ఓం గణేశాయ నమః',
      family: 'వారి',
      weddingInvitation: 'పెండ్లి పిలుపు',
      sonOf: 'గారి కుమారుడు',
      daughterOf: 'గారి కుమార్తె',
      mr: 'శ్రీ.',
      mrs: 'శ్రీమతి.',
      weddingOn: 'ముహూర్తం',
      venue: 'వేదిక'
    },
    props: {
      type: 'web',
      language: 'telugu',
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      surname: 'కోహ్లీ',
      message: 'వివాహ వేడుకలను జరుపుకోవడానికి మిమ్మల్ని హృదయపూర్వకంగా ఆహ్వానిస్తున్నాము',
      groomName: 'విరాట్ కోహ్లీ',
      groomImage: '',
      groomFatherName: 'లేట్. ప్రేమనాథ్ కోహ్లీ',
      groomMotherName: 'సరోజ్',
      brideName: 'అనుష్క శర్మ',
      brideImage: '',
      brideFatherName: 'Rtd. Col. అజయ్ కుమార్ శర్మ',
      brideMotherName: 'అషిమా',
      date: 'గురువారం, 21 డిసెంబర్ 2017',
      venue: 'దర్బార్ హాల్, తాజ్ డిప్లొమాటిక్ ఎనక్లేవ్ (ఎస్ పి మార్గ్ ఎంట్రన్స్), న్యూ ఢిల్లీ.'
    }
  }
};
