import { z } from 'zod';
import { LANGUAGES, VIDEO_TYPES } from '../../../src/shared/config/constants';
import { FORM_TYPES } from '../../../src/shared/types/types';

export const birthdaySchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  name: z.string(),
  message: z.string(),
  image: z.string()
});

export const birthdayWishesForm = [
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter name' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' },
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' }
];

export const birthdayWishesPlatforms = {
  web: {
    compositionWidth: 1280,
    compositionHeight: 720,
    compositionDuration: 150
  }
};

export const birthdayTags = [
  'online birthday invitations',
  'digital birthday invites',
  'custom birthday invitations',
  'birthday invitation templates',
  'online birthday wishes',
  'birthday card creation',
  'virtual birthday invitations',
  'personalized birthday invites',
  'birthday invitation design',
  'e-invitation for birthday',
  'birthday wishes online',
  'create birthday invitations',
  'birthday invitation maker',
  'DIY birthday invitations',
  'birthday invitation messages',
  'e-card birthday wishes',
  'birthday greeting cards online',
  'online birthday party invitations',
  'digital birthday greetings',
  'printable birthday invitations',
  'customizable birthday invites',
  'interactive birthday invitations',
  'birthday invitation cards',
  'unique birthday invitations',
  'birthday invitation ideas',
  'birthday party e-invites',
  'personalized birthday cards',
  'virtual birthday greetings',
  'birthday wishes design',
  'custom birthday wishes',
  'online birthday party cards',
  'birthday invitation customization',
  'digital birthday celebration invites',
  'birthday invitation online',
  'online birthday invitation services',
  'birthday invitation RSVP',
  'custom digital birthday invites',
  'birthday e-invite design',
  'personalized e-invitations',
  'birthday invitation graphics',
  'digital birthday cards',
  'interactive birthday e-cards',
  'birthday party invitation creation',
  'design birthday invitations online',
  'birthday celebration e-invites',
  'custom birthday e-cards',
  'digital birthday party invites',
  'birthday invitation design tools',
  'online birthday wishes creation',
  'unique digital birthday invitations',
  'personalized birthday invitation templates',
  'virtual birthday celebration cards',
  'editable birthday invitations',
  'online birthday event invitations',
  'digital birthday invite maker',
  'birthday invitations with RSVP',
  'online birthday wishes messages',
  'customizable e-birthday cards',
  'birthday invitation creation platform',
  'virtual birthday card designs',
  'birthday e-card creation tools',
  'create online birthday wishes',
  'digital birthday party cards',
  'custom birthday greeting e-cards',
  'birthday invitation designs online',
  'personalized digital birthday wishes'
];

export const birthdayWishesLanguages = {
  english: {
    labels: {
      wish: 'Happy Birthday'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Birthday Wishes',
      description: 'Happy Birthday!',
      name: 'Nayan Reddy',
      message:
        'May you be gifted with life’s biggest joys and never-ending bliss. After all, you yourself are a gift to earth, so you deserve the best.',
      image: ''
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'జన్మదిన శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Birthday Wishes',
      description: 'Happy Birthday!',
      name: 'నయన్ రెడ్డి',
      message:
        'మీ భవిష్యత్తు మరింత శోభాయమానంగా, ఉన్నతంగా, మీరు మరిన్ని ఉన్నత శిఖరాలు అధిరోహించి, సమున్నతంగా, సంపూర్ణ ఆయురారోగ్యాలతో నిండు నూరేళ్ళు సంతోషంగా వుండాలని ఆశిస్తూ...',
      image: ''
    }
  }
  // tamil: {
  //   labels: {
  //     ki: 'கே',
  //     happyBirthday: 'பிறந்தநாள் வாழ்த்துக்கள்'
  //   },
  //   props: {
  //     type: VIDEO_TYPES.WEB,
  //     language: LANGUAGES.TAMIL,
  //     title: 'Birthday Wishes',
  //     description: 'Happy Birthday!',
  //     name: 'நயன் ரெட்டி',
  //     message:
  //       'உண்மையான அன்பை வார்த்தைகளால் வெளிப்படுத்த முடியாது. அதை உணர்வுகளால் மட்டுமே வெளிப்படுத்த முடியும். மகிழ்ச்சி பொங்க இனிய பிறந்தநாள் வாழ்த்துகள்',
  //     image: ''
  //   }
  // }
};
