import { Series } from 'remotion';
import { LANGUAGES } from '../../src/shared/config/constants';
import { Text } from './Text';

interface Props {
  className: string;
  color: string;
  name: string;
  message: string;
  language: string;
  labels: any;
}

const WishesContent = (props: Props) => {
  const { name, message, labels, className, color, language } = props;
  return (
    <Series>
      <Series.Sequence durationInFrames={150}>
        <div className={className}>
          <Text className="text-[32px] mb-5 text-center leading-relaxed line-clamp-4" color={color} from={30} to={80}>
            {message}
          </Text>
          <div className="flex flex-row items-center justify-center font-medium">
            {language === LANGUAGES.TELUGU && (
              <Text className="text-[35px] text-center" color={color} from={60} to={100}>
                {name} {labels.to}&nbsp;
              </Text>
            )}
            <Text className="text-[35px] text-center" color={color} from={60} to={100}>
              {labels.wish}
            </Text>
            {language === LANGUAGES.ENGLISH && (
              <Text className="text-[35px] text-center" color={color} from={60} to={100}>
                &nbsp;{labels.to}&nbsp;{name}
              </Text>
            )}
          </div>
        </div>
      </Series.Sequence>
    </Series>
  );
};

export default WishesContent;
