import { LANGUAGES } from '../../../src/shared/config/constants';
import { Center } from '../../helpers/Center';
import { Image } from '../../helpers/Image';
import { Text } from '../../helpers/Text';
import brideImg from './assets/bride.jpg';
import ganeshaImg from './assets/ganesha.png';
import groomImg from './assets/groom.jpg';
import venueImg from './assets/venue.png';
import weddingImg from './assets/wedding.png';

export const MarriageWelcome = ({ image, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image source={image || ganeshaImg} from={0} to={20} className="w-[200px] h-[200px]" />
      <Text className="text-[30px] mt-5" color={color} from={10} to={30}>
        || {labels.welcomeMsg} ||
      </Text>
    </Center>
  );
};

export const MarriageTitle = ({ props, color, labels, className = '' }) => {
  return (
    <Center className={className}>
      <Text className="text-[30px] uppercase mb-3" color={color} from={0} to={20}>
        {props.surname} {labels.family}
      </Text>
      <Text className="text-[35px]" color={color} from={0} to={20}>
        {labels.weddingInvitation}
      </Text>
    </Center>
  );
};

export const MarriageMessage = ({ color, props, className = '' }) => {
  return (
    <Center className={className}>
      <Text className="text-[30px] max-w-[700px] text-center uppercase leading-relaxed" color={color} from={0} to={20}>
        {props.message}
      </Text>
    </Center>
  );
};

export const MarriageGroomDetails = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image
        source={props.groomImage || groomImg}
        from={0}
        to={20}
        className="w-[180px] h-[180px] border-[5px] rounded-full mb-5"
        style={{ borderColor: color }}
      />
      <Text className="text-[35px] mb-5" color={color} from={10} to={30}>
        {props.groomName}
      </Text>
      {props.language === LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center" color={color} from={20} to={40}>
          {labels.sonOf}
        </Text>
      )}
      <Text className="text-[30px] text-center" color={color} from={20} to={40}>
        {labels.mr} {props.groomFatherName} & {labels.mrs} {props.groomMotherName}
      </Text>
      {props.language === LANGUAGES.TELUGU && (
        <Text className="text-[25px] text-center mt-3" color={color} from={20} to={40}>
          {labels.sonOf}
        </Text>
      )}
    </Center>
  );
};

export const MarriageBrideDetails = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image
        source={props.brideImage || brideImg}
        from={0}
        to={20}
        className="w-[180px] h-[180px] border-[5px] rounded-full mb-5"
        style={{ borderColor: color }}
      />
      <Text className="text-[35px] mb-5" color={color} from={10} to={30}>
        {props.brideName}
      </Text>
      {props.language === LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center" color={color} from={20} to={40}>
          {labels.daughterOf}
        </Text>
      )}
      <Text className="text-[30px] text-center" color={color} from={20} to={40}>
        {labels.mr} {props.brideFatherName} & {labels.mrs} {props.brideMotherName}
      </Text>
      {props.language === LANGUAGES.TELUGU && (
        <Text className="text-[25px] text-center mt-3" color={color} from={20} to={40}>
          {labels.daughterOf}
        </Text>
      )}
    </Center>
  );
};

export const MarriageTiming = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image source={weddingImg} from={0} to={20} className="w-[200px] h-[200px] mb-5" />
      <Text className="text-[25px] mb-3" color={color} from={10} to={30}>
        {labels.weddingOn}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center" color={color} from={10} to={30}>
        {props.date}
      </Text>
    </Center>
  );
};

export const MarriageVenue = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image source={venueImg} from={0} to={20} className="w-[200px] h-[200px] mb-5" />
      <Text className="text-[25px] mb-3" color={color} from={10} to={30}>
        {labels.venue}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center leading-relaxed" color={color} from={10} to={30}>
        {props.venue}
      </Text>
    </Center>
  );
};
