import { Img, Series, useCurrentFrame } from 'remotion';
import { LANGUAGES } from '../../src/shared/config/constants';
import birthdayBoy from '../compositions/birthday/assets/birthday-boy.jpg';
import { fadeInEffect, heartBeatEffect } from '../services/utils';
import { Text } from './Text';

interface Props {
  className: string;
  color: string;
  name: string;
  message: string;
  image?: string;
  language: string;
  labels: any;
}

const WishesPhotoContent = (props: Props) => {
  const { name, message, image, labels, className, color, language } = props;
  const frame = useCurrentFrame();
  const totalFrames = 60;
  const opacity = fadeInEffect(frame, 0, 30);
  const scale = heartBeatEffect(frame % totalFrames, totalFrames);

  return (
    <Series>
      <Series.Sequence durationInFrames={150}>
        <div className={className}>
          <Img
            className="w-[150px] h-[150px] rounded-full mb-5 border-[5px]"
            style={{ transform: `scale(${scale})`, opacity, borderColor: color }}
            src={image || birthdayBoy}
          />
          <Text className="text-[32px] mb-5 text-center leading-relaxed line-clamp-4" color={color} from={30} to={80}>
            {message}
          </Text>
          <div className="flex flex-row items-center justify-center font-medium">
            {language === LANGUAGES.TELUGU && (
              <Text className="text-[35px] text-center" color={color} from={60} to={100}>
                {name} {labels.to}&nbsp;
              </Text>
            )}
            <Text className="text-[35px] text-center" color={color} from={60} to={100}>
              {labels.wish}
            </Text>
            {language === LANGUAGES.ENGLISH && (
              <Text className="text-[35px] text-center" color={color} from={60} to={100}>
                &nbsp;{labels.to}&nbsp;{name}
              </Text>
            )}
          </div>
        </div>
      </Series.Sequence>
    </Series>
  );
};

export default WishesPhotoContent;
