import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { NButton } from 'nayan';
import useRazorpay, { RazorpayOptions } from 'react-razorpay';
import { CONFIG } from '../../shared/config/config';
import { QUERY_KEYS } from '../../shared/hooks/hooksUtils';
import { RestService } from '../../shared/services/RestService';
import { useUserStore } from '../../shared/stores/UserStore';

interface Props {
  title: string;
  amount: string;
  videoUuid?: string;
  disabled?: boolean;
}

const Payments = (props: Props) => {
  const queryClient = useQueryClient();
  const { title, amount, videoUuid = '', disabled = false } = props;
  const [isLoading, setIsLoading] = useState(false);
  const userUuid = useUserStore.getState().UUID;
  const [Razorpay] = useRazorpay();

  const handlePayment = useCallback(async () => {
    setIsLoading(true);
    const payment: any = await RestService.post('/payments', { amount, videoUuid, userUuid });

    const options: RazorpayOptions = {
      key: CONFIG.PAYMENT_KEY,
      amount,
      currency: 'INR',
      name: CONFIG.DISPLAY_NAME,
      description: CONFIG.TITLE,
      image: CONFIG.FILES_URL + 'static/logo.png',
      order_id: payment?.data?.order?.id,
      handler: async res => {
        const updatedPayment = await RestService.update('/payments/' + payment?.data?.uuid, { uuid: payment?.data?.uuid, userUuid, payment: res });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENTS] });
        updatedPayment.data?.videoUuid && (await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VIDEOS, updatedPayment.data?.videoUuid] }));
      },
      prefill: {
        name: useUserStore.getState().UNAME,
        email: 'hello@inyter.com',
        contact: '9876543210'
      },
      notes: {
        uuid: useUserStore.getState().UUID
      },
      theme: {
        color: '#005ee6'
      }
    };

    const rzPay = new Razorpay(options);
    setIsLoading(false);
    rzPay.open();
  }, [Razorpay]);

  return (
    <NButton isLoading={isLoading} onClick={handlePayment} disabled={disabled}>
      {title}
    </NButton>
  );
};

export default Payments;
