import { useMemo } from 'react';
import { z } from 'zod';
import { getTemplateVideo, templateSkus } from '../../../src/services/TemplatesService';
import WishesPhotoContent from '../../helpers/WishesPhotoContent';
import { Wraper } from '../../helpers/Wraper';
import birthdayVideo from './assets/birthday-vectors.mp4';
import birthdayMusic from './assets/birthday.mp3';
import { birthdaySchema } from './BirthdaySchema';

const BirthdayWishesWithVectorsComposition: React.FC<z.infer<typeof birthdaySchema>> = props => {
  const { language, color = '#3691d1', name, message, image } = props;
  const template = useMemo(() => getTemplateVideo('birthday', templateSkus.birthdayVectorsWishes), []);
  const labels = template.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={1} music={birthdayMusic}>
      <WishesPhotoContent
        className="w-[850px] m-auto flex flex-col justify-center items-center mt-[120px]"
        labels={labels}
        language={language}
        name={name}
        message={message}
        image={image}
        color={color}
      />
    </Wraper>
  );
};

export default BirthdayWishesWithVectorsComposition;
