import { useState } from 'react';
import { QueryCache, useQueryClient } from '@tanstack/react-query';
import { Bell, Lock, Power, Trash2, User, WalletMinimal } from 'lucide-react';
import { NCard, NConfirmAlert } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { StorageService } from '../../services/StorageService';
import { setUserFromStorage } from '../../services/WebUtils';
import { ACCOUNT_TABS } from '../../shared/types/types';

interface Props {
  selectedTab: ACCOUNT_TABS;
  changeTab: any;
}

const SettingsMenu = (props: Props) => {
  const { selectedTab } = props;
  const navigate = useNavigate();
  const restCache = new QueryCache();
  const queryClient = useQueryClient();
  const [showAlert, setShowAlert] = useState(false);

  const logout = async () => {
    StorageService.unset('TOKEN');
    StorageService.unset('UUID');
    StorageService.unset('UNAME');
    StorageService.unset('UABOUT');
    await setUserFromStorage();
    await restCache.clear();
    await queryClient.clear();
    await queryClient.removeQueries();
    await queryClient.invalidateQueries();
    navigate('/', { replace: true });
  };

  return (
    <NCard className="sticky top-[70px]">
      <NConfirmAlert
        isOpen={showAlert}
        message="Are you sure to logout from this account?"
        onResult={result => result && logout()}
        onClose={() => setShowAlert(false)}
      />
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.PROFILE ? '-primary' : ''}`}
        onClick={() => props.changeTab(ACCOUNT_TABS.PROFILE)}>
        <User size={16} className="text-text" />
        <span>Account & Videos</span>
      </div>
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.NOTIFICATIONS ? '-primary' : ''}`}
        onClick={() => props.changeTab(ACCOUNT_TABS.NOTIFICATIONS)}>
        <Bell size={16} className="text-text" />
        <span>Notifications</span>
      </div>
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.SUBSCRIPTIONS ? '-primary' : ''}`}
        onClick={() => props.changeTab(ACCOUNT_TABS.SUBSCRIPTIONS)}>
        <WalletMinimal size={16} className="text-text" />
        <span>Subscriptions</span>
      </div>
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.DELETE_ACCOUNT ? '-primary' : ''}`}
        onClick={() => props.changeTab(ACCOUNT_TABS.DELETE_ACCOUNT)}>
        <Trash2 size={16} className="text-text" />
        <span>Delete Account</span>
      </div>
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.CHANGE_PASSWORD ? '-primary' : ''}`}
        onClick={() => props.changeTab(ACCOUNT_TABS.CHANGE_PASSWORD)}>
        <Lock size={16} className="text-text" />
        <span>Change Password</span>
      </div>
      <div
        tabIndex={0}
        role="button"
        className={`sidebar-menu-item item-left-border${selectedTab === ACCOUNT_TABS.LOGOUT ? '-primary' : ''}`}
        onClick={() => setShowAlert(true)}>
        <Power size={16} className="text-text" />
        <span>Logout</span>
      </div>
    </NCard>
  );
};

export default SettingsMenu;
