import { NInfiniteScroll, NLoading } from 'nayan';
import { formatInfiniteQueryData } from '../../shared/hooks/hooksUtils';
import { useSavedData } from '../../shared/services/ActionsService';
import { AccountVideosItem } from './AccountVideosItem';

export const AccountSaved = () => {
  const { isLoading, data, fetchNextPage, hasNextPage, isFetching } = useSavedData();
  const videos = formatInfiniteQueryData(data) || [];

  return (
    <div className="">
      {!!isLoading && <NLoading />}
      {!isLoading && !videos.length && <div className="p-10 text-center">No saved videos available.</div>}
      {!isLoading && !!videos.length && (
        <NInfiniteScroll
          next={() => !isFetching && fetchNextPage()}
          hasMore={!!hasNextPage}
          loader={<NLoading />}
          dataLength={videos.length}
          scrollThreshold={0.99}>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3 mb-3 p-0.5">
            {videos.map((video: any) => (
              <AccountVideosItem video={video} key={video.uuid} />
            ))}
          </div>
        </NInfiniteScroll>
      )}
    </div>
  );
};
