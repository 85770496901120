import { ReactNode } from 'react';
import { cn } from 'nayan';
import { AbsoluteFill, Audio, Img, OffthreadVideo, Sequence } from 'remotion';
import { CONFIG } from '../../src/shared/config/config';
import { Watermark } from './Watermark.tsx';

interface Props {
  children: ReactNode;
  className?: string;
  mediaClassName?: string;
  playbackRate?: number;
  musicVolume?: number;
  image?: any;
  music?: any;
  video?: any;
  color?: string;
  template?: any;
  data?: any;
}

export const Wraper = (props: Props) => {
  const { template, data, color = '#000000' } = props;
  return (
    <AbsoluteFill className={props.className}>
      {props.music && (
        <Sequence from={0}>
          <Audio src={props.music} volume={props.musicVolume || 1} delayRenderTimeoutInMilliseconds={CONFIG.TIMEOUT} />
        </Sequence>
      )}
      {props.image && (
        <AbsoluteFill>
          <Img
            className={cn(`w-full h-full blur opacity-75 ${props.mediaClassName}`)}
            src={props.image}
            delayRenderTimeoutInMilliseconds={CONFIG.TIMEOUT}
          />
        </AbsoluteFill>
      )}
      {props.video && (
        <AbsoluteFill>
          <OffthreadVideo
            src={props.video}
            className={cn(`w-full h-full ${props.mediaClassName}`)}
            playbackRate={props.playbackRate || 1}
            delayRenderTimeoutInMilliseconds={CONFIG.TIMEOUT}
          />
        </AbsoluteFill>
      )}
      {!data?.isPremium && <Watermark color={color} template={template} />}
      {props.children}
    </AbsoluteFill>
  );
};
