import SubHeader from '../helpers/SubHeader';

const About = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="About Us" />
      <div className="mb-5">Welcome to Inyter, your premier destination for creating memorable video invitations and wishes!</div>
      <div className="mb-5">
        At Inyter, we believe in the power of personalized communication to enhance special moments in life. Whether it's a birthday, wedding,
        graduation, or any other milestone, our easy-to-use application empowers you to craft unique and engaging video invitations and wishes that
        leave a lasting impression on your loved ones.
      </div>

      <h2 className="text-base font-semibold mb-5">Our Mission</h2>

      <div className="mb-5">
        Our mission at Inyter is to provide a platform that simplifies the process of creating and sending heartfelt messages for any occasion. We
        strive to offer innovative tools and features that enable our users to express themselves creatively and authentically, while fostering
        connections and strengthening relationships.
      </div>

      <h2 className="text-base font-semibold mb-5">Why Choose Inyter?</h2>

      <div className="mb-5">
        <strong>Ease of Use:</strong> With our intuitive interface and user-friendly design, creating personalized video invitations and wishes has
        never been easier. Simply select a template, customize it with your own photos, videos, and messages, and send it off to your recipients in
        minutes.
      </div>
      <div className="mb-5">
        <strong>Versatility:</strong> Whether you're planning a small gathering or a large event, Inyter offers a variety of templates and
        customization options to suit your needs. From elegant wedding invitations to playful birthday wishes, our collection has something for every
        occasion.
      </div>
      <div className="mb-5">
        <strong>Quality and Reliability:</strong> We are committed to delivering high-quality videos and reliable service to our users. Our platform
        is built on robust technology and infrastructure to ensure smooth performance and timely delivery of your messages.
      </div>

      <div className="text-sm text leading-7">
        <strong>Location:</strong> &nbsp;Bangalore, India.
      </div>
      <div className="text-sm text leading-8 py-3">
        <strong>Email:</strong> &nbsp;
        <a className="text-primary" href="mailto:hello@inyter.com" target="_blank">
          hello@inyter.com
        </a>
      </div>
    </div>
  );
};

export default About;
