export enum AUTH_TABS {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  FORGOT = 'FORGOT'
}

export enum ACCOUNT_TABS {
  PROFILE = 'profile',
  NOTIFICATIONS = 'notifications',
  SUBSCRIPTIONS = 'subscriptions',
  CHANGE_PASSWORD = 'change-password',
  DELETE_ACCOUNT = 'delete-account',
  LOGOUT = 'logout'
}

export enum ACTION_TYPES {
  VIDEO_LIKE = 'VIDEO_LIKE',
  VIDEO_COMMENT = 'VIDEO_COMMENT',
  VIDEO_SAVE = 'VIDEO_SAVE',
  VIDEO_REPORT = 'VIDEO_REPORT',
  VIDEO_VIEW = 'VIDEO_VIEW',
  USER_REPORT = 'USER_REPORT',
  USER_BLOCK = 'USER_BLOCK'
}

export enum ACTION_STATUS {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DELETED = 'DELETED'
}

export const ALLOWED_NOTIFICATIONS = [ACTION_TYPES.VIDEO_LIKE, ACTION_TYPES.VIDEO_COMMENT];

export enum UPLOAD_TYPES {
  VIDEOS = 'videos'
}

export enum SOCKET_ACTIONS {
  SEND_ALL = 'SEND_ALL',
  JOIN_ROOM = 'JOIN_ROOM',
  LEAVE_ROOM = 'LEAVE_ROOM',
  SEND_TO_ROOM = 'SEND_TO_ROOM',
  UPDATE_VIDEO = 'UPDATE_VIDEO',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION'
}

export enum FORM_TYPES {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DATE = 'date',
  DATETIME = 'datetime-local',
  FILE = 'file'
}

export enum VIDEO_STATUS {
  CREATED = 'CREATED',
  RENDERING = 'RENDERING',
  RENDERED = 'RENDERED',
  FAILED = 'FAILED'
}
