import { useEffect } from 'react';
import { NCard, NLoading } from 'nayan';
import { useParams } from 'react-router';
import { getTemplateVideo } from '../../services/TemplatesService';
import { CONFIG } from '../../shared/config/config';
import { LANGUAGES } from '../../shared/config/constants';
import { formatQueryData } from '../../shared/hooks/hooksUtils';
import { useVideoData } from '../../shared/services/VideosService';
import { useVideoStore } from '../../shared/stores/VideoStore';
import { HowItWorks } from '../helpers/HowItWorks';
import { Meta } from '../helpers/Meta';
import Tags from '../helpers/Tags';
import VideosPlayer from './VideosPlayer';

interface Props {
  children: any;
}

export const VideosWrapper = (props: Props) => {
  const params: any = useParams();
  const video = useVideoStore(state => state.video);
  const template = useVideoStore(state => state.template);
  const setVideo = useVideoStore(state => state.setVideo);
  const setTemplate = useVideoStore(state => state.setTemplate);
  const resetTemplate = useVideoStore(state => state.resetTemplate);
  const setCompositionId = useVideoStore(state => state.setCompositionId);
  const setCompositionType = useVideoStore(state => state.setCompositionType);
  const setDefaultVideoData = useVideoStore(state => state.setDefaultVideoData);
  const resetVideoData = useVideoStore(state => state.resetVideoData);
  const { isLoading, data: videoData } = useVideoData(params.uuid);
  const dataDB = formatQueryData(videoData);

  const templateMeta = !!params.video ? getTemplateVideo(params.type, params.video) : null;

  useEffect(() => {
    const temp =
      !!params.uuid && !isLoading && !!dataDB
        ? getTemplateVideo(dataDB?.compositionType, dataDB?.compositionId)
        : getTemplateVideo(params.type, params.video);

    const language = dataDB?.language || LANGUAGES.ENGLISH;

    setTemplate(temp);
    if (!!temp) {
      setDefaultVideoData(temp.languages[language].props);
      setCompositionType(temp.template.sku);
      setCompositionId(temp.sku);
    }
    if (!!dataDB) {
      setVideo(dataDB);
    }
    return () => {
      resetTemplate();
      resetVideoData();
    };
  }, [!!dataDB]);

  if (isLoading) {
    return <NLoading />;
  }

  if (!video) {
    return <div className="p-10 text-center">Video not available!</div>;
  }

  return (
    <div className="container mx-auto px-3 sm:px-0">
      <Meta
        title={templateMeta?.name}
        description={templateMeta?.description}
        image={`${CONFIG.APP_URL}/images/templates/${templateMeta?.sku}.jpg`}
      />
      <div className="grid md:grid-cols-6 grid-cols-1 gap-3 mb-3">
        <NCard className="p-3 md:col-span-4 col-span-1">
          <VideosPlayer template={template} />
        </NCard>
        <NCard className="md:col-span-2 col-span-1 p-3 max-w-full h-auto md:max-h-[calc(100vh_-_85px)] overflow-y-auto">{props.children}</NCard>
      </div>
      <div className="leading-relaxed text-base pt-5 pb-10">
        Make every occasion unforgettable with our user-friendly platform designed for creating personalized video invitations and heartfelt wishes.
        Whether you're celebrating a wedding, birthday, baby shower, anniversary, or any other special event, our tools help you craft stunning videos
        that will leave a lasting impression. Our platform is designed to empower you with creativity and simplicity. Begin by selecting your desired
        occasion from a diverse array of categories tailored to meet various themes and styles. Whether you envision an elegant wedding announcement
        or a playful birthday invitation, our extensive collection of templates ensures there's something perfect for every event.
      </div>
      <HowItWorks />
      <Tags tags={template?.template?.tags || []} />
    </div>
  );
};
