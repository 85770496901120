import Resizer from 'react-image-file-resizer';
import io from 'socket.io-client';
import { CONFIG } from '../shared/config/config';
import { RestService } from '../shared/services/RestService';
import { useUserStore } from '../shared/stores/UserStore';
import { StorageService } from './StorageService';

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const resizeImage = async (file: any, maxWidth: number = 512, maxHeight: number = 512) => {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      70,
      0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });
};

export const setUserFromStorage = async () => {
  const UUID = StorageService.get('UUID');
  const TOKEN = StorageService.get('TOKEN');
  const UNAME = StorageService.get('UNAME');
  const UABOUT = StorageService.get('UABOUT');
  RestService.setToken(TOKEN);
  return useUserStore.setState({ UUID, UNAME, UABOUT, TOKEN });
};

export const toFixed = (num: number, prc: number = 2) => {
  return num.toFixed(prc);
};

export const socketConnect = () => {
  const TOKEN = useUserStore.getState().TOKEN;
  return io(CONFIG.SOCKET_URL + '?token=' + TOKEN, {
    transports: ['websocket']
  });
};
