import { useState } from 'react';
import { QueryCache, useQueryClient } from '@tanstack/react-query';
import { NButton, NInput, useToast } from 'nayan';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { StorageService } from '../../services/StorageService';
import { setUserFromStorage } from '../../services/WebUtils';
import { loginUser } from '../../shared/services/AuthService';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const restCache = new QueryCache();
  const queryClient = useQueryClient();
  const search = useLocation().search;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const redirect: any = new URLSearchParams(search).get('redirect') || '/account';
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if (!email || !password) {
      toast('You have to enter required details to continue.');
      return;
    }
    setIsLoading(true);
    const data = JSON.stringify({ email, password }, null, 2);
    loginUser(data)
      .then(async (res: any) => {
        StorageService.set('TOKEN', res.token);
        StorageService.set('UUID', res.uuid);
        StorageService.set('UNAME', res.name);
        StorageService.set('UABOUT', res.about);
        await setUserFromStorage();
        await restCache.clear();
        await queryClient.clear();
        await queryClient.removeQueries();
        await queryClient.invalidateQueries();
        navigate(redirect, { replace: true });
      })
      .catch((error: any) => {
        toast(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <NInput className="mb-3 card-input" label="Email" type="email" onChange={e => setEmail(e.target.value)} required={true} />
        <NInput className="mb-3 card-input" label="Password" type="password" onChange={e => setPassword(e.target.value)} required={true} />

        <div className="mb-3 text-end">
          <Link className="text-primary cursor-pointer" to="/forgot-password">
            Forgot password ?
          </Link>
        </div>

        <NButton type="submit" className="w-full mb-3" isLoading={isLoading}>
          LOGIN
        </NButton>
      </form>

      <div className="text-center text">
        Don't have an account ?{' '}
        <Link className="text-primary cursor-pointer" to="/signup">
          Signup
        </Link>
      </div>
    </>
  );
};

export default Login;
