import { useState } from 'react';
import { NButton, NCard, NInput, useToast } from 'nayan';
import { changePassword } from '../../shared/services/AuthService';
import { useUserStore } from '../../shared/stores/UserStore';
import SubHeader from '../helpers/SubHeader';

export const AccountPassword = () => {
  const toast = useToast();
  const myUuid = useUserStore.getState().UUID;
  const [isLoading, setIsLoading] = useState(false);
  const [old_password, setOldPassword] = useState('');
  const [new_password, setNewPassword] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    if (old_password && new_password && old_password !== new_password) {
      const data = { uuid: myUuid, old_password, new_password };
      setIsLoading(true);
      changePassword(data)
        .then(() => {
          setNewPassword('');
          setOldPassword('');
          toast('Password change successful.');
        })
        .catch((error: any) => toast(error.message))
        .finally(() => setIsLoading(false));
    } else {
      toast('Invalid credentials, enter correct one.');
    }
  };

  return (
    <div>
      <SubHeader title="Change Password" />
      <NCard className="grid grid-cols-1 lg:grid-cols-2 p-3">
        <div className="col-span-1">
          <form onSubmit={onSubmit}>
            <NInput label="Old password" type="password" value={old_password} onChange={e => setOldPassword(e.target.value)} />
            <NInput label="New password" type="password" value={new_password} onChange={e => setNewPassword(e.target.value)} />
            <NButton type="submit" isLoading={isLoading}>
              Change Passwird
            </NButton>
          </form>
        </div>
      </NCard>
    </div>
  );
};
