import { useState } from 'react';
import { NButton, NInput, NTextarea, useToast } from 'nayan';
import { useUpdateUserMutation } from '../../shared/services/UsersService';
import { useUserStore } from '../../shared/stores/UserStore';

interface Props {
  onClose: any;
}

export const AccountProfileEdit = (props: Props) => {
  const toast = useToast();
  const user = useUserStore(state => state);
  const [name, setName] = useState(user.UNAME || '');
  const [about, setAbout] = useState(user.UABOUT || '');
  const updateUserMutation = useUpdateUserMutation();

  const onSubmit = e => {
    e.preventDefault();
    const finalUser = { uuid: user.UUID, name, about };
    updateUserMutation.mutateAsync(finalUser).then(() => props.onClose().catch(e => toast(e.message)));
  };

  return (
    <div className="p-3">
      <form onSubmit={onSubmit}>
        <NInput label="Name" value={name} onChange={e => setName(e.target.value)} />
        <NTextarea className="mb-3" label="About" value={about} onChange={e => setAbout(e.target.value)} />
        <NButton type="submit">Update</NButton>
      </form>
    </div>
  );
};
