import { NDivider } from 'nayan';
import { Link } from 'react-router-dom';
import { DateService } from '../../shared/services/DateService';

const Footer = () => {
  return (
    <section className="bg-card flex flex-col min-h-[254px]">
      <NDivider />
      <div className="container mx-auto px-3 sm:px-0">
        <div className="grid md:grid-cols-7 sm:grid-cols-4 grid-cols-1 gap-16 pb-3 pt-5">
          <div className="md:col-span-3 sm:col-span-4 col-span-1">
            <div className="text-base text-text mb-2">ABOUT INYTER</div>
            <div className="text-sm leading-8">
              Inyter is the ultimate solution for crafting personalized video invitations and heartfelt wishes. With user-friendly features, it makes
              event planning a breeze. Customize templates, music, and text to create memorable messages for any occasion. Share joy effortlessly with
              Inyter.
            </div>
          </div>
          <div className="md:col-span-2 sm:col-span-2 col-span-1">
            <div className="text-base text-text mb-2">QUICK LINKS</div>
            <ul className="text-text text-sm m-0 list-none">
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/">
                  Home
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/templates">
                  Templates
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/about">
                  About Us
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="md:col-span-2 sm:col-span-2 col-span-1">
            <div className="text-base text-text mb-2">POLICIES</div>
            <ul className="text-text text-sm m-0 list-none">
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/terms-of-use">
                  Terms of Use
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/privacy-policies">
                  Privacy Policies
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link className="text-text hover:text-primary no-underline py-2.5" to="/cancellation-policies">
                  Cancellation Policies
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <NDivider />
        <div className="md:flex justify-between items-center py-3">
          <div className="text-sm text text-center md:mb-0 mb-2">All Rights Reserved © {DateService.getFullYear()}</div>
          <div className="text-sm text text-center">Made with ❤️ in India</div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
