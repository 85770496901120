import { z } from 'zod';
import { LANGUAGES, VIDEO_TYPES } from '../../../src/shared/config/constants';
import { FORM_TYPES } from '../../../src/shared/types/types';

export const festivalSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  name: z.string(),
  message: z.string()
});

export const festivalWishesPlatforms = {
  web: {
    compositionWidth: 1280,
    compositionHeight: 720,
    compositionDuration: 150
  }
};

export const festivalWishesForm = [
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter name' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' }
];

export const festivalTags = [
  'Indian festivals wishes',
  'Diwali greetings',
  'Holi wishes',
  'Navratri greetings',
  'Eid Mubarak wishes',
  'Ganesh Chaturthi wishes',
  'Raksha Bandhan wishes',
  'Pongal greetings',
  'Dussehra wishes',
  'Durga Puja greetings',
  'Makar Sankranti wishes',
  'Lohri wishes',
  'Janmashtami greetings',
  'Onam wishes',
  'Christmas in India',
  'New Year wishes India',
  'Baisakhi greetings',
  'Ugadi wishes',
  'Karva Chauth wishes',
  'Chhath Puja greetings',
  'Gudi Padwa wishes',
  'Vaisakhi greetings',
  'Makar Sankranti celebrations',
  'Indian festival greetings',
  'Happy Diwali',
  'Happy Holi',
  'Eid greetings',
  'Ganesh Festival wishes',
  'Rakhi wishes',
  'Pongal celebrations',
  'Durga Puja wishes',
  'Navratri festival greetings',
  'Diwali festival wishes',
  'Holi festival greetings',
  'Navratri festival wishes',
  'Eid al-Fitr greetings',
  'Ganesh Chaturthi celebrations',
  'Raksha Bandhan messages',
  'Pongal festival wishes',
  'Dussehra festival greetings',
  'Durga Puja celebrations',
  'Makar Sankranti festival wishes',
  'Lohri festival greetings',
  'Janmashtami festival wishes',
  'Onam festival greetings',
  'Christmas celebrations in India',
  'New Year celebrations India',
  'Baisakhi festival wishes',
  'Ugadi festival greetings',
  'Karva Chauth festival wishes',
  'Chhath Puja festival greetings',
  'Gudi Padwa festival wishes',
  'Vaisakhi festival wishes',
  'Makar Sankranti festival greetings',
  'Indian festival wishes and greetings',
  'Happy Diwali messages',
  'Happy Holi wishes',
  'Eid greetings and wishes',
  'Ganesh Chaturthi festival wishes',
  'Rakhi festival greetings',
  'Pongal festival messages',
  'Durga Puja festival wishes',
  'Navratri festival greetings and wishes'
];

export const festivalRakhiWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Raksha Bandhan'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Raksha Bhandan Wishes',
      description: 'Happy Raksha Bandhan!',
      name: 'All of you',
      message: 'You are the most special person in my life and I pray that all your dreams come true. May you always be happy and healthy!'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'రక్షా బంధన్ శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Raksha Bhandan Wishes',
      description: 'Happy Raksha Bandhan!',
      name: 'మీ అందరి',
      message:
        'అనుబంధాల హరివిల్లు ప్రేమాభిమానాల పొదరిల్లు గిల్లికజ్జాల సరదాలు తోడు నీడగా సాగిన జీవితాలు కాలం మారినా.. దూరం పెరిగినా.. చెరగని బంధాలు.. అవే అన్నా చెల్లెళ్ల అనుబంధాలు.. కలకాలం నిలవాలి ఈ రక్షాబంధాలు.'
    }
  }
};

export const festivalRamadanWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Ramadan'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Ramadan Wishes',
      description: 'Happy Ramadan!',
      name: 'All of you',
      message: 'Sending you heartfelt wishes for a Ramadan filled with love, harmony, and countless blessings.'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'రంజాన్ శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Ramadan Wishes',
      description: 'Happy Ramadan!',
      name: 'మీ అందరి',
      message:
        'ఈ రంజాన్, మీ ప్రార్థనలకు సమాధానాలు లభిస్తాయని మరియు అవి మిమ్మల్ని అల్లాహ్‌కు మరియు అంతర్గత శాంతికి దగ్గరగా తీసుకువస్తాయని నేను ఆశిస్తున్నాను.'
    }
  }
};

export const festivalDiwaliWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Diwali'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Diwali Wishes',
      description: 'Happy Diwali!',
      name: 'All of you',
      message: 'May the joy, cheer, mirth and merriment of this divine festival surround you forever. May the happiness that this season brings.'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'దీపావళి శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Diwali Wishes',
      description: 'Happy Diwali!',
      name: 'మీ అందరి',
      message: 'ఈ దీపావళి నాడు, మీరు మంచి ఆరోగ్యం, సంపద మరియు విజయంతో ఆశీర్వదించబడండి. మీకు మరియు మీ కుటుంబ సభ్యులకు దీపావళి శుభాకాంక్షలు!'
    }
  }
};

export const festivalHoliWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Holi'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Holi Wishes',
      description: 'Happy Holi!',
      name: 'All of you',
      message: 'May the colours of Holi paint your life with happiness, prosperity, and success. Happy Holi to you and your family!'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'హోలి శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Holi Wishes',
      description: 'Happy Holi!',
      name: 'మీ అందరి',
      message: 'ఈ హోలీ మీ జీవితంలో ఆనందం, ప్రేమ మరియు స్నేహం యొక్క రంగులను తీసుకురావాలి. మీకు మరియు మీ కుటుంబ సభ్యులకు హోలీ శుభాకాంక్షలు!'
    }
  }
};

export const festivalChristmasWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Christmas'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Christmas Wishes',
      description: 'Happy Christmas!',
      name: 'All of you',
      message: 'May all that is beautiful, meaningful and brings you joy be yours this holiday season and throughout the coming year!'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'క్రిస్మస్ శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Christmas Wishes',
      description: 'Happy Christmas!',
      name: 'మీ అందరి',
      message:
        'ఈ క్రిస్మస్.. మీ జీవితంలో సంతోషాన్ని నింపాలని, మీ ఇంట ఆనందపు కాంతులు వెదజల్లాలని కోరుకుంటూ. మీకు, మీ కుటుంబ సభ్యులకు క్రిస్మస్ శుభాకాంక్షలు.'
    }
  }
};

export const festivalSnowmanWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Snowman Day'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Snowman Wishes',
      description: 'Happy Snowman Day!',
      name: 'All of you',
      message: 'Wishing you a Happy Snowman Day! May your day be as delightful and full of cheer as a snowman’s smile on a crisp winter morning.'
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'స్నోమన్ డే శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Snowman day Wishes',
      description: 'Happy Snowman day!',
      name: 'మీ అందరి',
      message: 'మీకు హ్యాపీ స్నోమన్ డే! మీ రోజు స్నోమన్ యొక్క నవ్వుతో కూడిన శీతల ఉదయంలా ఆనందకరమైనది మరియు హర్షభరితమైనది కావాలని ఆశిస్తున్నాను.'
    }
  }
};

export const festivalIndependenceWishesLanguages = {
  english: {
    labels: {
      to: 'to',
      wish: 'Happy Independence Day'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Independence Day Wishes',
      description: 'Happy Independence Day!',
      name: 'All of you',
      message: "Let's celebrate the power of unity that brought us independence and continues to guide our nation forward."
    }
  },
  telugu: {
    labels: {
      to: 'కి',
      wish: 'స్వాతంత్ర్య దినోత్సవ శుభాకాంక్షలు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Independence Day Wishes',
      description: 'Happy Independence Day!',
      name: 'మీ అందరి',
      message: 'మన స్వేచ్ఛా స్వాతంత్ర్యాల కోసం అశువులు బాసిన సమర యోధుల దీక్షా దక్షతలను స్మరిస్తూ...'
    }
  }
};
