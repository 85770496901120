import {
  birthdayTags,
  birthdayWishesForm,
  birthdayWishesLanguages,
  birthdayWishesPlatforms
} from '../../remotion/compositions/birthday/BirthdaySchema';
import BirthdayWishesWithBalloonsComposition from '../../remotion/compositions/birthday/BirthdayWishesWithBalloonsComposition';
import BirthdayWishesWithFlowersComposition from '../../remotion/compositions/birthday/BirthdayWishesWithFlowersComposition';
import BirthdayWishesWithSpringsComposition from '../../remotion/compositions/birthday/BirthdayWishesWithSpringsComposition';
import BirthdayWishesWithVectorsComposition from '../../remotion/compositions/birthday/BirthdayWishesWithVectorsComposition';
import {
  festivalChristmasWishesLanguages,
  festivalDiwaliWishesLanguages,
  festivalHoliWishesLanguages,
  festivalIndependenceWishesLanguages,
  festivalRakhiWishesLanguages,
  festivalRamadanWishesLanguages,
  festivalSnowmanWishesLanguages,
  festivalTags,
  festivalWishesForm,
  festivalWishesPlatforms
} from '../../remotion/compositions/festivals/FestivalsSchema';
import FestivalWishesForChristmas from '../../remotion/compositions/festivals/FestivalWishesForChristmas';
import FestivalWishesForDiwali from '../../remotion/compositions/festivals/FestivalWishesForDiwali';
import FestivalWishesForDiwaliWithDiyas from '../../remotion/compositions/festivals/FestivalWishesForDiwaliWithDiyas';
import FestivalWishesForHoli from '../../remotion/compositions/festivals/FestivalWishesForHoli';
import FestivalWishesForIndependenceDay from '../../remotion/compositions/festivals/FestivalWishesForIndependenceDay';
import FestivalWishesForRakshaBandan from '../../remotion/compositions/festivals/FestivalWishesForRakshaBandan';
import FestivalWishesForRamadan from '../../remotion/compositions/festivals/FestivalWishesForRamadan';
import FestivalWishesForSnowman from '../../remotion/compositions/festivals/FestivalWishesForSnowman';
import MarriageFlowersInvitationComposition from '../../remotion/compositions/marriage/MarriageFlowersInvitationComposition';
import MarriageGarlandsInvitationComposition from '../../remotion/compositions/marriage/MarriageGarlandsInvitationComposition';
import MarriageLightsInvitationComposition from '../../remotion/compositions/marriage/MarriageLightsInvitationComposition';
import {
  marriageInvitationForm,
  marriageInvitationLanguages,
  marriageInvitationPlatforms,
  marriageTags
} from '../../remotion/compositions/marriage/MarriageSchema';
import MarriageVintageInvitationComposition from '../../remotion/compositions/marriage/MarriageVintageInvitationComposition';
import { VIDEO_TYPES, WATERMARK_POSITION } from '../shared/config/constants';

export const getTemplate = (type: string) => {
  return templates.find(t => t.sku === type);
};

export const getTemplateVideo = (type, videoSku: any) => {
  const template = templates.find(t => t.sku === type);
  const video = template?.videos?.find((v: any) => v.sku === videoSku);
  if (!template || !video) return null;
  return { ...video, template: { name: template.name, sku: template.sku, tags: template.tags } };
};

export const templateSkus = {
  birthdayBalloonsWishes: 'birthday-wishes-with-balloons-animation',
  birthdayFlowersWishes: 'birthday-wishes-with-flowers-animation',
  birthdayVectorsWishes: 'birthday-wishes-with-vectors-animation',
  birthdaySpringsWishes: 'birthday-wishes-with-springs-animation',
  marriageVintageInvitation: 'marriage-invitation-with-vintage-animation',
  marriageLightsInvitation: 'marriage-invitation-with-lights-animation',
  marriageFlowersInvitation: 'marriage-invitation-with-flowers-animation',
  marriageGarlandsInvitation: 'marriage-invitation-with-garlands-animation',
  festivalRakshaBandanWishes: 'festival-wishes-for-raksha-bandan',
  festivalRamadanWishes: 'festival-wishes-for-ramadan',
  festivalChristmasWishes: 'festival-wishes-for-christmas',
  festivalDiwaliWishes: 'festival-wishes-for-diwali',
  festivalDiwaliWithDiyasWishes: 'festival-wishes-for-diwali-with-diyas',
  festivalHoliWishes: 'festival-wishes-for-holi',
  festivalSnowmanWishes: 'festival-wishes-for-Snowman',
  festivalIndependenceDayWishes: 'festival-wishes-for-independence-day'
};

export const compositionMapping = {
  [`${templateSkus.birthdayBalloonsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithBalloonsComposition,
  [`${templateSkus.birthdayFlowersWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithFlowersComposition,
  [`${templateSkus.birthdayVectorsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithVectorsComposition,
  [`${templateSkus.birthdaySpringsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithSpringsComposition,
  [`${templateSkus.marriageVintageInvitation}-${VIDEO_TYPES.WEB}`]: MarriageVintageInvitationComposition,
  [`${templateSkus.marriageLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageLightsInvitationComposition,
  [`${templateSkus.marriageFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageFlowersInvitationComposition,
  [`${templateSkus.marriageGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageGarlandsInvitationComposition,
  [`${templateSkus.festivalRakshaBandanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRakshaBandan,
  [`${templateSkus.festivalRamadanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRamadan,
  [`${templateSkus.festivalSnowmanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForSnowman,
  [`${templateSkus.festivalHoliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForHoli,
  [`${templateSkus.festivalIndependenceDayWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForIndependenceDay,
  [`${templateSkus.festivalDiwaliWithDiyasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwaliWithDiyas,
  [`${templateSkus.festivalChristmasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmas,
  [`${templateSkus.festivalDiwaliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwali
};

export const templates = [
  {
    name: 'Birthday',
    sku: 'birthday',
    tags: birthdayTags,
    videos: [
      {
        name: 'Birthday Wishes with Balloons Animation',
        description: 'Create birthday wishes with balloons animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPLEFT,
        sku: templateSkus.birthdayBalloonsWishes,
        form: birthdayWishesForm,
        platforms: birthdayWishesPlatforms,
        languages: birthdayWishesLanguages
      },
      {
        name: 'Birthday Wishes with Flowers Animation',
        description: 'Create birthday wishes with flowers animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPCENTER,
        sku: templateSkus.birthdayFlowersWishes,
        form: birthdayWishesForm,
        platforms: birthdayWishesPlatforms,
        languages: birthdayWishesLanguages
      },
      {
        name: 'Birthday Wishes with Vectors Animation',
        description: 'Create birthday wishes with vectors animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPCENTER,
        sku: templateSkus.birthdayVectorsWishes,
        form: birthdayWishesForm,
        platforms: birthdayWishesPlatforms,
        languages: birthdayWishesLanguages
      },
      {
        name: 'Birthday Wishes with Springs Animation',
        description: 'Create birthday wishes with springs animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
        sku: templateSkus.birthdaySpringsWishes,
        form: birthdayWishesForm,
        platforms: birthdayWishesPlatforms,
        languages: birthdayWishesLanguages
      }
    ]
  },
  {
    name: 'Marriage',
    sku: 'marriage',
    tags: marriageTags,
    videos: [
      {
        name: 'Marriage invitation with vintage animation',
        description: 'Create Marriage invitations with vintage animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPCENTER,
        sku: templateSkus.marriageVintageInvitation,
        form: marriageInvitationForm,
        platforms: marriageInvitationPlatforms,
        languages: marriageInvitationLanguages
      },
      {
        name: 'Marriage invitation with lights animation',
        description: 'Create Marriage invitations with lights animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPCENTER,
        sku: templateSkus.marriageLightsInvitation,
        form: marriageInvitationForm,
        platforms: marriageInvitationPlatforms,
        languages: marriageInvitationLanguages
      },
      {
        name: 'Marriage invitation with flowers animation',
        description: 'Create Marriage invitations with flowers animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
        sku: templateSkus.marriageFlowersInvitation,
        form: marriageInvitationForm,
        platforms: marriageInvitationPlatforms,
        languages: marriageInvitationLanguages
      },
      {
        name: 'Marriage invitation with garlands animation',
        description: 'Create Marriage invitations with garlands animation for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
        sku: templateSkus.marriageGarlandsInvitation,
        form: marriageInvitationForm,
        platforms: marriageInvitationPlatforms,
        languages: marriageInvitationLanguages
      }
    ]
  },
  {
    name: 'Festivals',
    sku: 'festivals',
    tags: festivalTags,
    videos: [
      {
        name: 'Festival Wishes for Raksha Bandhan',
        description: 'Create festival wishes for Raksha bandhan for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPLEFT,
        sku: templateSkus.festivalRakshaBandanWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalRakhiWishesLanguages
      },
      {
        name: 'Festival Wishes for Holi',
        description: 'Create festival wishes for Holi for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
        sku: templateSkus.festivalHoliWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalHoliWishesLanguages
      },
      {
        name: 'Festival Wishes for Christmas',
        description: 'Create festival wishes for Christmas for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
        sku: templateSkus.festivalChristmasWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalChristmasWishesLanguages
      },
      {
        name: 'Festival Wishes for Diwali',
        description: 'Create festival wishes for Diwali for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
        sku: templateSkus.festivalDiwaliWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalDiwaliWishesLanguages
      },
      {
        name: 'Festival Wishes for Diwali with diyas',
        description: "Create festival wishes for Diwali with diya's for your friends and family.",
        watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
        sku: templateSkus.festivalDiwaliWithDiyasWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalDiwaliWishesLanguages
      },
      {
        name: 'Festival Wishes for Ramadan',
        description: 'Create festival wishes for Ramadan for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPCENTER,
        sku: templateSkus.festivalRamadanWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalRamadanWishesLanguages
      },
      {
        name: 'Festival Wishes for Independence Day',
        description: 'Create festival wishes for Independence Day for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPLEFT,
        sku: templateSkus.festivalIndependenceDayWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalIndependenceWishesLanguages
      },
      {
        name: 'Festival Wishes for Snowman',
        description: 'Create festival wishes for Snowman for your friends and family.',
        watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
        sku: templateSkus.festivalSnowmanWishes,
        form: festivalWishesForm,
        platforms: festivalWishesPlatforms,
        languages: festivalSnowmanWishesLanguages
      }
    ]
  },
  {
    name: 'House Warming',
    sku: 'house-warming',
    tags: [],
    videos: []
  },
  {
    name: 'Kids',
    sku: 'kids',
    tags: [],
    videos: []
  },
  {
    name: 'Save The Date',
    sku: 'save-the-date',
    tags: [],
    videos: []
  },
  {
    name: 'Life Events',
    sku: 'life-events',
    tags: [],
    videos: []
  },
  {
    name: 'Technology',
    sku: 'technology',
    tags: [],
    videos: []
  }
];
