import { Helmet } from 'react-helmet-async';
import { CONFIG } from '../../shared/config/config';

interface Props {
  name?: string;
  type?: string;
  title?: string;
  image?: string;
  description?: string;
}

export const Meta = (props: Props) => {
  const { name = CONFIG.DISPLAY_NAME, type = 'article', title = CONFIG.TITLE, description = CONFIG.DESCRIPTION, image = CONFIG.BANNER_URL } = props;

  const finalTitle = CONFIG.DISPLAY_NAME + ' - ' + title;

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{finalTitle}</title>
      <link rel="canonical" href={CONFIG.APP_URL} />
      <meta name="description" content={description} />
      <meta property="keywords" content={CONFIG.KEYWORDS} />

      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={CONFIG.APP_URL} />
      <meta property="og:image" content={image} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:url" content={CONFIG.APP_URL} />
      <meta property="twitter:image" content={image} />
      {/* End Twitter tags */}
    </Helmet>
  );
};
