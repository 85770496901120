import { ArrowBigDownDash, Bookmark, Flag, Heart, Share2 } from 'lucide-react';
import { NDivider, NLinkify, NPopover, useToast } from 'nayan';
import { useParams } from 'react-router';
import { CONFIG } from '../../shared/config/config';
import { createAction, useAddActionMutation, useRemoveActionMutation } from '../../shared/services/ActionsService';
import { DateService } from '../../shared/services/DateService';
import { useUserStore } from '../../shared/stores/UserStore';
import { useVideoStore } from '../../shared/stores/VideoStore';
import { ACTION_TYPES } from '../../shared/types/types';
import PaymentBanner from '../payments/PaymentBanner';
import { VideosComments } from './VideosComments';
import { VideosShare } from './VideosShare';
import { VideosWrapper } from './VideosWrapper';

const Videos = () => {
  const params: any = useParams();
  const toast = useToast();
  const userUuid = useUserStore(state => state.UUID);
  const video = useVideoStore(state => state.video);
  const addActionMutation = useAddActionMutation();
  const removeActionMutation = useRemoveActionMutation();

  const actionPost = (type: string, video: any, isRemove: boolean) => {
    if (!userUuid) {
      return toast('Please login to perform this action.');
    }
    if (isRemove) {
      removeActionMutation.mutate({ videoUuid: video.uuid, userUuid, type });
    } else {
      const action = createAction(type, userUuid, video.userUuid, video.uuid);
      addActionMutation.mutate(action);
    }
  };

  const downloadVideo = () => {
    window.open(CONFIG.FILES_URL + 'videos/' + video.uuid + '.mp4', '_blank');
  };

  return (
    <VideosWrapper>
      <h1 className="text-lg mb-1">{video.data.title}</h1>
      <div className="mb-1">
        <NLinkify>{video.data.description}</NLinkify>
      </div>
      <div className="text-sm text-muted mb-2">
        {video.name} &middot; {DateService.format(video.created)}
      </div>
      {!!params.uuid && video.userUuid === userUuid && !video.isPremium && <PaymentBanner videoUuid={params.uuid} />}
      {(!params.uuid || video.userUuid !== userUuid || !!video.isPremium) && <NDivider className="mb-3 mt-2" />}
      <div className="flex justify-start items-center pb-3 border-0 border-b border-border">
        <div
          role="button"
          tabIndex={0}
          className="cursor-pointer post-icons mr-4"
          onClick={() => actionPost(ACTION_TYPES.VIDEO_LIKE, video, !!video.isLiked)}>
          <Heart size="1.7em" className={!!video.isLiked ? 'text-primary fill-primary' : 'text-muted fill-muted'} />
        </div>
        <div role="button" tabIndex={0} className="cursor-pointer mr-4" onClick={() => actionPost(ACTION_TYPES.VIDEO_SAVE, video, !!video.isSaved)}>
          <Bookmark size="1.7em" className={!!video.isSaved ? 'text-primary fill-primary' : 'text-muted fill-muted'} />
        </div>
        <div
          role="button"
          tabIndex={0}
          className="cursor-pointer mr-4"
          onClick={() => actionPost(ACTION_TYPES.VIDEO_REPORT, video, !!video.isReported)}>
          <Flag size="1.7em" className={!!video.isReported ? 'text-primary fill-primary' : 'text-muted fill-muted'} />
        </div>
        {video.status === 'RENDERED' && (
          <div role="button" tabIndex={0} className="cursor-pointer mr-4" onClick={downloadVideo}>
            <ArrowBigDownDash size="1.9em" className="text-muted fill-muted" />
          </div>
        )}
        <NPopover size="LG" trigger={<Share2 role="button" size="1.7em" className="text-muted fill-muted" />}>
          <VideosShare video={video} />
        </NPopover>
      </div>

      <div className="flex justify-between item-center mb-2 mt-2">
        <div className="posts-text text-muted text text-xs">
          <span className="cursor-pointer">{video.likes} Likes </span> &middot; <span className="cursor-pointer"> {video.comments} Comments </span>
        </div>
        <div className="posts-text text-muted text text-xs">{video.views} Views</div>
      </div>

      <VideosComments video={video} />
    </VideosWrapper>
  );
};

export default Videos;
