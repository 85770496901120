import { useEffect, useState } from 'react';
import { PencilRuler } from 'lucide-react';
import { NDialog, NLoading, NTabs, NTabsContent } from 'nayan';
import { StorageService } from '../../services/StorageService';
import { setUserFromStorage } from '../../services/WebUtils';
import { useUserData } from '../../shared/services/UsersService';
import { useUserStore } from '../../shared/stores/UserStore';
import SubHeader from '../helpers/SubHeader';
import { AccountProfileEdit } from './AccountProfileEdit';
import { AccountSaved } from './AccountSaved';
import { AccountVideos } from './AccountVideos';

const items = ['VIDEOS', 'SAVED'];

export const AccountProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUserStore(state => state);
  const [selected, setSelected] = useState(items[0]);
  const { isLoading, data } = useUserData(user.UUID);

  useEffect(() => {
    if (data?.data) {
      const usr = data.data;
      StorageService.set('UNAME', usr.name);
      StorageService.set('UABOUT', usr.about);
      setUserFromStorage();
    }
  }, [data?.data]);

  if (isLoading) {
    return <NLoading />;
  }

  return (
    <div className="profile">
      <NDialog isOpen={isOpen} onClose={() => setIsOpen(false)} title="Edit Profile">
        <AccountProfileEdit onClose={() => setIsOpen(false)} />
      </NDialog>
      <SubHeader title={user.UNAME} clickButton={() => setIsOpen(true)} buttonText="EDIT" buttonIcon={PencilRuler} />
      <div className="mb-3">{user.UABOUT || user.UNAME + ' Invitations & Wishes.'}</div>
      <NTabs items={items} selected={selected} onChange={setSelected}>
        <NTabsContent item={items[0]}>
          <AccountVideos />
        </NTabsContent>
        <NTabsContent item={items[1]}>
          <AccountSaved />
        </NTabsContent>
      </NTabs>
    </div>
  );
};
