import { useState } from 'react';
import { X } from 'lucide-react';
import { NConfirmAlert, NLinkify } from 'nayan';
import { useRemoveActionMutation } from '../../shared/services/ActionsService';
import { DateService } from '../../shared/services/DateService';
import { useUserStore } from '../../shared/stores/UserStore';
import { ACTION_TYPES } from '../../shared/types/types';

interface Props {
  comment: any;
  video: any;
}

export const VideosCommentsItem = (props: Props) => {
  const { comment, video } = props;
  const userUuid = useUserStore(state => state.UUID);
  const [isOpen, setIsOpen] = useState(false);
  const removeActionMutation = useRemoveActionMutation();

  const removeComment = uuid => {
    removeActionMutation.mutate({ videoUuid: video.uuid, userUuid, type: ACTION_TYPES.VIDEO_COMMENT, uuid });
  };

  return (
    <div key={comment.uuid} className="py-2 border-0 border-b border-border">
      <NConfirmAlert
        isOpen={isOpen}
        title="Are you absolutely sure?"
        message="This action will delete permanently, Are you sure to delete this comment?"
        onResult={result => result && removeComment(comment.uuid)}
        onClose={() => setIsOpen(false)}
      />
      <div className="flex justify-between items-center">
        <div className="font-semibold mb-1">
          {comment.name} &middot; <span className="font-normal text-xs text-muted">{DateService.format(comment.created)}</span>
        </div>
        {(comment.userUuid === userUuid || video.userUuid === userUuid) && (
          <div role="button" onClick={() => setIsOpen(true)}>
            <X className="w-4 h-4 text-primary cursor-pointer" />
          </div>
        )}
      </div>
      <div className="text-sm">
        <NLinkify>{comment.comment}</NLinkify>
      </div>
    </div>
  );
};
