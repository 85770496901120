import { useMemo } from 'react';
import { z } from 'zod';
import { getTemplateVideo, templateSkus } from '../../../src/services/TemplatesService';
import WishesContent from '../../helpers/WishesContent';
import { Wraper } from '../../helpers/Wraper';
import festivalVideo from './assets/festival-indipendenceday.mp4';
import festivalMusic from './assets/independence-day.mp3';
import { festivalSchema } from './FestivalsSchema';

const FestivalWishesForIndependenceDay: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#3f45b4', name, message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalIndependenceDayWishes), []);
  const labels = template.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} playbackRate={0.9} music={festivalMusic}>
      <WishesContent
        className="w-[850px] flex flex-col justify-end pl-8 pb-16"
        labels={labels}
        language={language}
        name={name}
        message={message}
        color={color}
      />
    </Wraper>
  );
};

export default FestivalWishesForIndependenceDay;
